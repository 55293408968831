import { useContext, useCallback } from 'react';
import { useSelector } from 'react-redux';

import { RootState } from 'src/store';

import { AppConfigContext } from 'src/providers/appConfig';

import {
  AppApiUserFromTelegramWebAppDataRequestDto,
  AppApiUserFromTelegramWebAppDataResponseDto,
  AppApiUserMeResponseDto,
  AppApiUserListReferredsResponseDto,
  AppApiUserMyPointResponseDto,
  AppApiStatisticPointResponseDto,
  AppApiQuoteGetRandomRequestDto,
  AppApiQuoteGetRandomResponseDto,
} from 'src/types/appApi';

export const useAppApi = () => {
  const appConfig = useContext(AppConfigContext);
  const token = useSelector((state: RootState) => state.initialParam.token);

  const addApiUrl = useCallback(
    (input: RequestInfo) => {
      if (typeof input === 'string') return `${appConfig.apiUrl}${input}`;
      return input;
    },
    [appConfig],
  );
  const addHeaders = useCallback(
    (init: RequestInit) => {
      init.headers = {
        ...init.headers,
        Accept: 'application/json',
        'Content-Type': 'application/json',
        ...(token
          ? {
              token,
            }
          : {}),
      };
      return init;
    },
    [token],
  );
  const appFetch = useCallback(
    (input: RequestInfo, init: RequestInit) => {
      return fetch(addApiUrl(input), addHeaders(init));
    },
    [addApiUrl, addHeaders],
  );

  const appApiUserFromTelegramWebAppData = useCallback(
    async ({ webAppData }: AppApiUserFromTelegramWebAppDataRequestDto) => {
      let data: AppApiUserFromTelegramWebAppDataResponseDto | null = null;
      try {
        const res = await appFetch('/user/fromTelegramWebAppData', {
          method: 'PUT',
          body: JSON.stringify({
            webAppData,
          } as AppApiUserFromTelegramWebAppDataRequestDto),
        });
        if (res.status !== 200) {
          return Promise.reject(res.status);
        }
        data = await res.json();
      } catch (error) {
        console.error(error);
      }
      return data;
    },
    [appFetch],
  );
  const appApiUserMe = useCallback(async () => {
    let data: AppApiUserMeResponseDto | null = null;
    try {
      const res = await appFetch('/user/me', { method: 'GET' });
      if (res.status !== 200) {
        return Promise.reject(res.status);
      }
      data = await res.json();
    } catch (error) {
      console.error(error);
    }
    return data;
  }, [appFetch]);
  const appApiUserListReferreds = useCallback(async () => {
    let data: AppApiUserListReferredsResponseDto | null = null;
    try {
      const res = await appFetch('/user/listReferred', { method: 'GET' });
      if (res.status !== 200) {
        return Promise.reject(res.status);
      }
      data = await res.json();
    } catch (error) {
      console.error(error);
    }
    return data;
  }, [appFetch]);
  const appApiUserMyPoint = useCallback(async () => {
    let data: AppApiUserMyPointResponseDto | null = null;
    try {
      const res = await appFetch('/user/myPoint', { method: 'GET' });
      if (res.status !== 200) {
        return Promise.reject(res.status);
      }
      data = await res.json();
    } catch (error) {
      console.error(error);
    }
    return data;
  }, [appFetch]);

  const appApiStatisticPoint = useCallback(async () => {
    let data: AppApiStatisticPointResponseDto | null = null;
    try {
      const res = await appFetch('/statistic/point', { method: 'GET' });
      if (res.status !== 200) {
        return Promise.reject(res.status);
      }
      data = await res.json();
    } catch (error) {
      console.error(error);
    }
    return data;
  }, [appFetch]);

  const appApiQuoteGetRandom = useCallback(
    async ({ take }: AppApiQuoteGetRandomRequestDto) => {
      let data: AppApiQuoteGetRandomResponseDto | null = null;
      let url = '/quote/getRandom?';
      if (take !== undefined) {
        url += new URLSearchParams({
          take: take.toString(),
        }).toString();
      }
      try {
        const res = await appFetch(url, {
          method: 'GET',
        });
        if (res.status !== 200) {
          return Promise.reject(res.status);
        }
        data = await res.json();
      } catch (error) {
        console.error(error);
      }
      return data;
    },
    [appFetch],
  );

  return {
    appApiUserFromTelegramWebAppData,
    appApiUserMe,
    appApiUserListReferreds,
    appApiUserMyPoint,
    appApiStatisticPoint,
    appApiQuoteGetRandom,
  };
};
