import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { RootState } from 'src/store';

import { AppConfigContext } from 'src/providers/appConfig';

export const useInviteLink = () => {
  const appConfig = useContext(AppConfigContext);
  const userMe = useSelector((state: RootState) => state.appApi.userMe);

  const [inviteLink, setInviteLink] = useState('');

  useEffect(() => {
    if (!userMe) {
      setInviteLink('');
      return;
    }
    const startParam = btoa(`referrerCode=${userMe.user.code}`);
    setInviteLink(
      `${appConfig.botUrl}/${appConfig.botApp}?startApp=${startParam}&startapp=${startParam}`,
    );
  }, [appConfig, userMe]);

  return { inviteLink };
};
