import React, { Suspense } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import { RouteConstants } from 'src/constants/route';

import ComponentLoading from 'src/components/loading';

const ScreenTabs = React.lazy(() => import('./tabs'));
const ScreenTabHome = React.lazy(() => import('./tabs/home'));
const ScreenTabFrens = React.lazy(() => import('./tabs/frens'));

const Router: React.FC = () => {
  return (
    <>
      <Routes>
        <Route
          path={`${RouteConstants.PATH_TAB}`}
          element={
            <Suspense fallback={<ComponentLoading />}>
              <ScreenTabs />
            </Suspense>
          }
        >
          <Route
            path={`${RouteConstants.PATH_TAB}${RouteConstants.PATH_TAB_HOME}`}
            element={
              <Suspense fallback={<ComponentLoading />}>
                <ScreenTabHome />
              </Suspense>
            }
          />
          <Route
            path={`${RouteConstants.PATH_TAB}${RouteConstants.PATH_TAB_FRIENDS}`}
            element={
              <Suspense fallback={<ComponentLoading />}>
                <ScreenTabFrens />
              </Suspense>
            }
          />
        </Route>
        <Route
          path="*"
          element={
            <Navigate replace to={`${RouteConstants.PATH_TAB}${RouteConstants.PATH_TAB_HOME}`} />
          }
        />
      </Routes>
    </>
  );
};

export default Router;
