import React, { useCallback, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { RootState } from 'src/store';
import { setModalInviteFrensShow } from 'src/store/modal';
import { setModalSelectQuoteShow } from 'src/store/modal';

import { useInviteLink } from 'src/hooks/useInviteLink';

import ComponentModalBottom from 'src/components/modalBottom';

import { copyTextToClipboard } from 'src/utilities/helper';

const ModalInviteFrens: React.FC = () => {
  const dispatch = useDispatch();

  const modalInviteFrens = useSelector((state: RootState) => state.modal.inviteFrens);

  const onShouldClose = useCallback(() => {
    dispatch(setModalInviteFrensShow(false));
  }, [dispatch]);

  const { inviteLink } = useInviteLink();
  const [isCopied, setIsCopied] = useState(false);
  const onCopyInviteLinkClicked = useCallback(() => {
    if (isCopied) {
      return;
    }
    setIsCopied(true);
    try {
      if (inviteLink) copyTextToClipboard(inviteLink);
    } catch (error) {
      console.error(error);
    }
    setTimeout(() => {
      setIsCopied(false);
      onShouldClose();
    }, 1000);
  }, [inviteLink, onShouldClose, isCopied]);

  const onShareInviteLinkClicked = useCallback(async () => {
    onShouldClose();
    setTimeout(() => {
      dispatch(setModalSelectQuoteShow(true));
    }, 100);
  }, [dispatch, onShouldClose]);

  return (
    <>
      <ComponentModalBottom
        show={modalInviteFrens.show}
        title="Invite Frens"
        onShouldClose={onShouldClose}
      >
        <button
          type="button"
          className="w-full text-black bg-white hover:bg-gray-200 active:bg-gray-400 disabled:bg-white font-semibold rounded-lg text-lg px-5 py-2.5"
          disabled={isCopied}
          onClick={onCopyInviteLinkClicked}
        >
          {isCopied ? 'Copied' : 'Copy invite link'}
        </button>
        <button
          type="button"
          className="w-full text-black bg-white hover:bg-gray-200 active:bg-gray-400 disabled:bg-white font-semibold rounded-lg text-lg px-5 py-2.5 mt-3"
          onClick={onShareInviteLinkClicked}
        >
          {'Share invite link'}
        </button>
      </ComponentModalBottom>
    </>
  );
};

export default ModalInviteFrens;
