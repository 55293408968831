import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import Cookies from 'js-cookie';

import { RootState } from './store';
import { setInitialParamToken } from './store/initialParam';
import {
  setAppApiUserMe,
  setAppApiUserMyPoint,
  setAppApiUserListReferrals,
  setAppApiStatisticPoint,
} from './store/appApi';

import { CookiesConstants } from './constants/cookies';

import { useInitialParam } from './hooks/useInitialParam';
import { useAppApi } from 'src/hooks/useAppApi';

import Router from './screens';
import ModalInviteFrens from './modals/inviteFrens';
import ModalSelectQuote from './modals/selectQuote';

const App: React.FC = () => {
  const dispatch = useDispatch();

  useInitialParam();

  const token = useSelector((state: RootState) => state.initialParam.token);
  const telegramWebAppData = useSelector(
    (state: RootState) => state.initialParam.telegramWebAppData,
  );

  const {
    appApiUserFromTelegramWebAppData,
    appApiUserMe,
    appApiUserMyPoint,
    appApiUserListReferreds,
    appApiStatisticPoint,
  } = useAppApi();

  useEffect(() => {
    if (!telegramWebAppData) {
      return;
    }
    appApiUserFromTelegramWebAppData({ webAppData: telegramWebAppData })
      .then((data) => {
        Cookies.set(CookiesConstants.TOKEN_KEY, data?.token || '', {
          expires: CookiesConstants.TOKEN_EXPIRES,
        });
        dispatch(setInitialParamToken(data?.token || ''));
      })
      .catch(() => {});
  }, [dispatch, telegramWebAppData, appApiUserFromTelegramWebAppData]);

  useEffect(() => {
    if (!token) {
      return;
    }

    appApiUserMe()
      .then((data) => {
        dispatch(setAppApiUserMe(data));
      })
      .catch(() => {});
  }, [dispatch, token, appApiUserMe]);

  useEffect(() => {
    if (!token) {
      return;
    }

    appApiUserMyPoint()
      .then((data) => {
        dispatch(setAppApiUserMyPoint(data));
      })
      .catch(() => {});
  }, [dispatch, token, appApiUserMyPoint]);

  useEffect(() => {
    if (!token) {
      return;
    }

    appApiUserListReferreds()
      .then((data) => {
        dispatch(setAppApiUserListReferrals(data));
      })
      .catch(() => {});
  }, [dispatch, token, appApiUserListReferreds]);

  useEffect(() => {
    if (!token) {
      return;
    }

    appApiStatisticPoint()
      .then((data) => {
        dispatch(setAppApiStatisticPoint(data));
      })
      .catch(() => {});
  }, [dispatch, token, appApiStatisticPoint]);

  return (
    <>
      <div className="flex flex-col items-center h-full select-none">
        <div className="w-[500px] max-w-full h-full">
          <div className="h-full relative py-10 px-5">
            <Router />
            <ModalInviteFrens />
            <ModalSelectQuote />
          </div>
        </div>
      </div>
    </>
  );
};

export default App;
