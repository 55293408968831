import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Cookies from 'js-cookie';

import { CookiesConstants } from 'src/constants/cookies';

import { setInitialParamToken, setInitialParamTelegramWebAppData } from 'src/store/initialParam';

export const useInitialParam = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const token = Cookies.get(CookiesConstants.TOKEN_KEY);
    if (token) {
      dispatch(setInitialParamToken(token));
    }
    // @ts-expect-error no telegram type
    const telegramWebAppData = window.Telegram.WebApp.initData;
    if (telegramWebAppData) {
      dispatch(setInitialParamTelegramWebAppData(telegramWebAppData));
    }
  }, [dispatch]);
};
