import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from 'src/store';
import { setModalSelectQuoteShow } from 'src/store/modal';

import { useInviteLink } from 'src/hooks/useInviteLink';
import { useAppApi } from 'src/hooks/useAppApi';

import { AppApiQuote } from 'src/types/appApi';

import ComponentModalBottom from 'src/components/modalBottom';

const TOTAL_QUOTES = 3;

const ModalSelectQuote: React.FC = () => {
  const dispatch = useDispatch();

  const modalSelectQuote = useSelector((state: RootState) => state.modal.selectQuote);

  const onShouldClose = useCallback(() => {
    dispatch(setModalSelectQuoteShow(false));
  }, [dispatch]);

  const { appApiQuoteGetRandom } = useAppApi();
  const [quotes, setQuotes] = useState<AppApiQuote[]>([]);
  const [selectedQuote, setSelectedQuote] = useState<AppApiQuote | null>(null);
  const getQuotes = useCallback(() => {
    setQuotes([]);
    setSelectedQuote(null);
    appApiQuoteGetRandom({ take: TOTAL_QUOTES }).then((data) => {
      if (!data?.quotes.length) {
        return;
      }
      setQuotes(data.quotes);
      setSelectedQuote(data.quotes[0]);
    });
  }, [appApiQuoteGetRandom]);
  useEffect(() => {
    if (modalSelectQuote.show) {
      getQuotes();
    }
  }, [modalSelectQuote.show, getQuotes]);
  const onGetOtherQuotesClicked = useCallback(() => {
    getQuotes();
  }, [getQuotes]);

  const { inviteLink } = useInviteLink();
  const onShareInviteLinkClicked = useCallback(async () => {
    try {
      const _inviteLink = encodeURIComponent(inviteLink);
      const inviteText = encodeURIComponent(`${selectedQuote?.content} - ${selectedQuote?.author}`);
      // @ts-expect-error no Telegram type
      window.Telegram.WebApp.openTelegramLink(
        `https://t.me/share/url?url=${_inviteLink}&text=${inviteText}`,
      );
    } catch (error) {
      console.error(error);
    }

    onShouldClose();
  }, [onShouldClose, inviteLink, selectedQuote]);

  return (
    <>
      <ComponentModalBottom
        show={modalSelectQuote.show}
        title="Select Quote"
        onShouldClose={onShouldClose}
      >
        {!quotes.length && (
          <>
            <div className="animate-pulse">
              {Array.from(Array(TOTAL_QUOTES).keys()).map((number) => (
                <div
                  key={number}
                  className={`rounded-lg bg-zinc-700 py-8 ${number ? 'mt-3' : ''}`}
                ></div>
              ))}
              <div className="rounded-lg bg-zinc-700 py-6 mt-5"></div>
              <div className="rounded-lg bg-zinc-700 py-6 mt-2"></div>
            </div>
          </>
        )}
        {!!quotes.length && (
          <>
            {quotes.map((quote, index) => (
              <div
                key={index}
                className={`group border-2 rounded-lg px-5 py-2 cursor-pointer hover:border-white ${quote.id === selectedQuote?.id ? 'border-white' : 'border-gray-500'} ${index ? 'mt-3' : ''}`}
                onClick={() => setSelectedQuote(quote)}
              >
                <p
                  className={`group-hover:text-white ${quote.id === selectedQuote?.id ? 'text-white' : 'text-gray-500'} `}
                >
                  {quote.content} - {quote.author}
                </p>
              </div>
            ))}
            <button
              type="button"
              className="w-full text-gray-500 border-2 border-gray-500 hover:border-white hover:text-white active:border-gray-200 active:text-gray-200 font-semibold rounded-lg text-lg px-5 py-2.5 mt-5"
              onClick={onGetOtherQuotesClicked}
            >
              <span>{'Get other quotes'}</span>
            </button>
            <button
              type="button"
              className="w-full text-black bg-white hover:bg-gray-200 active:bg-gray-400 disabled:bg-white font-semibold rounded-lg text-lg px-5 py-2.5 mt-2"
              disabled={!selectedQuote}
              onClick={onShareInviteLinkClicked}
            >
              {'Share invite link'}
            </button>
          </>
        )}
      </ComponentModalBottom>
    </>
  );
};

export default ModalSelectQuote;
