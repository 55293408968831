import React, { useEffect, useState } from 'react';

type ComponentModalBottomProps = {
  show: boolean;
  onShouldClose: () => void;
  title: string;
  children?: React.ReactNode;
};
const ComponentModalBottom: React.FC<ComponentModalBottomProps> = ({
  show,
  onShouldClose,
  title,
  children,
}) => {
  const [modalTop, setModalTop] = useState('top-[100%]');
  useEffect(() => {
    if (show) {
      setModalTop('top-0');
    } else {
      setModalTop('top-[100%]');
    }
  }, [show]);

  if (!show) {
    return <></>;
  }
  return (
    <>
      <div
        className={`absolute w-full h-full right-0 bottom-0 left-0 z-[1] transition-all duration-300 ${modalTop}`}
        onClick={() => {
          onShouldClose();
        }}
      >
        <div
          className="absolute right-0 bottom-0 left-0 z-[2] bg-zinc-800 rounded-t-lg"
          onClick={(event: React.MouseEvent<HTMLDivElement>) => event.stopPropagation()}
        >
          <div className="px-5 py-5 relative">
            <p className="text-white text-lg text-extrabold text-center">{title}</p>
            <button
              type="button"
              className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 disabled:bg-transparent disabled:text-gray-400 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center absolute top-5 right-5"
              onClick={() => {
                onShouldClose();
              }}
            >
              <svg
                className="w-3 h-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
          </div>
          <hr className="h-[2px] mx-5 bg-zinc-400 border-0"></hr>
          <div className="px-5 py-5">{children}</div>
        </div>
      </div>
    </>
  );
};

export default ComponentModalBottom;
